



































































































































































import { Component, Vue } from 'vue-property-decorator';
import { metaInfo } from '@/utils/seo/metaInfo';
import { buildUrl } from '@/utils/url';

@Component({
  components: {},
  metaInfo: metaInfo({
    title: 'Časté dotazy | Kakadoo',
    url: buildUrl('/caste-dotazy'),
    description:
      'Parkoviště po Praze na dosah ruky. Vyberte si termín a dodávku, registrujte se, zaplaťte a vyrazte! Všechno vyřídíte on-line.'
  })
})
export default class Faq extends Vue {}
